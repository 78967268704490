import FaceTwoTone from "@mui/icons-material/FaceTwoTone"
import { Avatar, IconButton } from "@mui/material"
import type { FC } from "react"

type Props = {
  alt: string
  fileId: string | null
  onClick(): void
}

export const AccountButton: FC<Props> = (props) => {
  const size = 32

  const src =
    props.fileId !== null
      ? `/api/images/${props.fileId}?w=${size}&q=100`
      : undefined

  return (
    <IconButton onClick={props.onClick}>
      <Avatar
        alt={props.alt}
        src={src}
        sx={{
          width: size,
          height: size,
          bgcolor: "transparent",
          color: "text.secondary",
        }}
      >
        <FaceTwoTone />
      </Avatar>
    </IconButton>
  )
}
