import CloseIcon from "@mui/icons-material/Close"
import { Alert, Button, IconButton } from "@mui/material"
import { useInviteLinkQuery } from "interface/__generated__/react"

interface InviteLinkInfoProps {
  invitationCode: string
  onJoin: () => void
  onClose: () => void
}

const InviteLinkInfo: React.FC<InviteLinkInfoProps> = ({
  invitationCode,
  onJoin,
  onClose,
}) => {
  const { data, error } = useInviteLinkQuery({
    variables: { code: invitationCode },
  })

  if (error) {
    return <div>ERR</div>
  }

  return (
    <>
      {data && (
        <Alert
          severity="info"
          action={
            <>
              <Button color="inherit" size="small" onClick={onJoin}>
                JOIN
              </Button>
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={onClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </>
          }
        >
          Requesting to join {data.inviteLink.business.name}.
        </Alert>
      )}
    </>
  )
}

export default InviteLinkInfo
