import { Card, CardActionArea, Chip, Stack, Typography } from "@mui/material"
import type { FC } from "react"

type Props = {
  businessLogin: string
  businessCompanyName: string
  businessName: string
  waitingForApproval: boolean
  onClick(): void
}

export const BusinessCard: FC<Props> = (props) => {
  return (
    <Card sx={{ height: "100%" }}>
      <CardActionArea sx={{ height: "100%" }} onClick={props.onClick}>
        <Stack sx={{ py: 4 }} spacing={1} alignItems={"center"}>
          {props.waitingForApproval && (
            <Chip label="awaiting approval" color="secondary" />
          )}
          <Typography fontWeight={"bold"} fontSize={14}>
            {props.businessCompanyName}
          </Typography>
          <Typography fontWeight={"bold"} fontSize={20}>
            {props.businessName}
          </Typography>
          <Typography
            fontWeight={"bold"}
          >{`@${props.businessLogin}`}</Typography>
        </Stack>
      </CardActionArea>
    </Card>
  )
}
