import AddTwoTone from "@mui/icons-material/AddTwoTone"
import { Card, CardActionArea, Stack, Typography } from "@mui/material"
import type { FC } from "react"

type Props = {
  onClick(): void
}

export const BusinessNewCard: FC<Props> = (props) => {
  return (
    <Card sx={{ height: "100%" }}>
      <CardActionArea onClick={props.onClick} sx={{ height: "100%" }}>
        <Stack sx={{ py: 4 }} spacing={2} alignItems={"center"}>
          <AddTwoTone sx={{ fontSize: 28 }} />
          <Typography fontWeight={"bold"}>{"Add Business"}</Typography>
        </Stack>
      </CardActionArea>
    </Card>
  )
}
