import type { BlitzPage } from "@blitzjs/next"
import { Box, Button, Stack, TextField, Typography } from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import Grid from "@mui/material/Unstable_Grid2"
import { getAuth } from "firebase/auth"
import { t } from "i18next"
import { useRouter } from "next/router"
import { useSnackbar } from "notistack"
import { useState, useDebugValue, useEffect } from "react"
import { BusinessCard } from "app/businesses/components/BusinessCard"
import { BusinessNewCard } from "app/businesses/components/BusinessNewCard"
import { AccountButton } from "app/components/AccountButton"
import { AddButton } from "app/components/AddButton"
import { HomeAppBar } from "app/components/HomeAppBar"
import  RootLoading from "app/loading"
import {
  useApproveInviteRequestMutation,
  useJoinByInviteLinkMutation,
  useViewerBusinessesQuery,
  useViewerUserQuery,
} from "interface/__generated__/react"
import InviteLinkInfo from "pages/inviteLinkInfo"
import {
  getFromLocalStorage,
  removeItemFromLocalStorage,
  storeInLocalStorage,
} from "util/localStorageUtil"

const RootPage: BlitzPage = () => {
  console.log("ENV:", process.env)
  console.log("NODE_ENV:", process.env.NODE_ENV)
  const router = useRouter()

  // パスから招待コードを呪録
  const invitationCode = Array.isArray(router.query.invitationCode)
    ? router.query.invitationCode[0]
    : router.query.invitationCode ?? null

  let inviteRequestId = Array.isArray(router.query.inviteRequest)
    ? router.query.inviteRequest[0]
    : router.query.inviteRequest ?? null

  if (!inviteRequestId) {
    inviteRequestId = getFromLocalStorage("inviteRequestId")
  }

  useDebugValue("invitationCode")

  const checkApproveInviteRequest = () => {
    const currentUser = getAuth().currentUser
    const isLogin = currentUser != null

    if (inviteRequestId && isLogin && !joinLoading) {
      approveInviteRequest({
        variables: { input: { inviteRequestId } },
      })
        .then(() => {
          // joinが成功したときの処理
          removeItemFromLocalStorage("inviteRequestId")
          refetchviewerBusinesses()
          enqueueSnackbar("Join successfully", { variant: "success" })
        })
        .catch((error) => {
          // joinが失敗したときの処理
          removeItemFromLocalStorage("inviteRequestId")
          enqueueSnackbar(error.message, { variant: "error" })
        })
    }
  }

  useEffect(() => {
    if (invitationCode) {
      storeInLocalStorage("invitationCode", invitationCode)
    }
    if (inviteRequestId) {
      storeInLocalStorage("inviteRequestId", inviteRequestId)
      checkApproveInviteRequest()
    }
  }, [inviteRequestId])

  const [approveInviteRequest, { loading: joinLoading, error: joinError }] =
    useApproveInviteRequestMutation()

  const [open, setOpen] = useState(false)
  const [name, setName] = useState("")
  const { enqueueSnackbar } = useSnackbar()

  const handleJoin = () => {
    setOpen(true)
  }

  const handleClose = () => {
    // Remove invitationCode query parameter
    router.push(router.pathname, undefined, { shallow: true })
  }

  const handleDialogClose = () => {
    setOpen(false)
  }

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const handleDialogSubmit = async () => {
    // Call your join API here with the input `name`
    // Then close the dialog
    try {
      await joinByInviteLink({
        variables: {
          input: {
            userId: viewer?.viewer?.user.id ?? "",
            name: name,
            inviteLinkCode: invitationCode ?? "",
          },
        },
      })

      router.push(router.pathname, undefined, { shallow: true })
      refetchviewerBusinesses()

      enqueueSnackbar("Request successfully", { variant: "success" })
    } catch (error) {
      if (error instanceof Error) {
        enqueueSnackbar(error.message, { variant: "error" })
      } else {
        enqueueSnackbar("Failed to login", { variant: "error" })
      }
    }
    setOpen(false)
  }

  // useJoinByInviteLinkMutation
  const [joinByInviteLink, { loading: isLoading }] =
    useJoinByInviteLinkMutation()

  const { data: viewer, error: viewerUserError } = useViewerUserQuery()
  if (viewerUserError) {
    throw viewerUserError
  }

  const {
    data: viewerBusiness,
    error: viewerBusinessesError,
    refetch: refetchviewerBusinesses,
    loading: viewerBusinessesLoading,
  } = useViewerBusinessesQuery({
    variables: { withDetails: false },
  })
  if (viewerBusinessesError) {
    throw viewerBusinessesError
  }

  const businesses = viewerBusiness?.viewer?.businesses ?? []
  const inviteLinkRequests = viewerBusiness?.viewer?.inviteLinkRequests ?? []

  return (
    <>
      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle>Join</DialogTitle>
        <DialogContent>
          <DialogContentText>Please enter your name.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            value={name}
            onChange={handleNameChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleDialogSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
      <HomeAppBar>
        <AccountButton
          alt={""}
          fileId={null}
          onClick={() => {
            router.push("/account")
          }}
        />
      </HomeAppBar>
      {viewerBusinessesLoading ? (
        <RootLoading />
      ) : (
        <Stack direction={"row"} justifyContent={"center"}>
          <Stack
            sx={{ maxWidth: 800, width: "100%", px: 2, mt: 2, mb: 2 }}
            spacing={4}
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              spacing={1}
            >
              <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                {t("Your Businesses")}
              </Typography>
              {viewer?.viewer?.user.stripeCustomerId && (
                <AddButton
                  onClick={() => {
                    router.push("/businesses/new")
                  }}
                  text={"Add Business"}
                />
              )}
            </Stack>
            {invitationCode && (
              <InviteLinkInfo
                invitationCode={invitationCode}
                onJoin={handleJoin}
                onClose={handleClose}
              />
            )}

            <Box>
              <Grid container spacing={2}>
                {businesses.map((business) => (
                  <Grid xs={12} sm={6} key={business.id}>
                    <BusinessCard
                      businessCompanyName={business.companyName ?? "-"}
                      businessLogin={business.login}
                      waitingForApproval={false}
                      businessName={business.name}
                      onClick={() => router.push(`/${business.login}`)}
                    />
                  </Grid>
                ))}
                {inviteLinkRequests.map((inviteLinkRequest) => (
                  <Grid xs={12} sm={6} key={inviteLinkRequest.id}>
                    <BusinessCard
                      businessCompanyName={
                        inviteLinkRequest.business.companyName ?? ""
                      }
                      waitingForApproval={true}
                      businessLogin={inviteLinkRequest.business.login}
                      businessName={inviteLinkRequest.business.name}
                      onClick={() => {}}
                    />
                  </Grid>
                ))}
                {businesses.length == 0 && (
                  <Grid xs={12} sm={6}>
                    <BusinessNewCard
                      onClick={() => {
                        router.push("/businesses/new")
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          </Stack>
        </Stack>
      )}
    </>
  )
}

RootPage.getLayout = (page) => {
  return <>{page}</>
}

export default RootPage
