export const storeInLocalStorage = (key: string, value: string | undefined) => {
  if (value) {
    localStorage.setItem(key, value)
  }
}

export const getFromLocalStorage = (key: string) => {
  return localStorage.getItem(key)
}

export const removeItemFromLocalStorage = (key: string) => {
  localStorage.removeItem(key)
}
