import AddTwoTone from "@mui/icons-material/AddTwoTone"
import { Button } from "@mui/material"
import type { FC } from "react"

type Props = {
  onClick(): void
  text: string
}

export const AddButton: FC<Props> = (props) => {
  return (
    <Button
      size={"small"}
      variant="text"
      startIcon={<AddTwoTone />}
      onClick={props.onClick}
    >
      {props.text}
    </Button>
  )
}
